<template>
  <CommonCard style="padding: 40px; margin: 40px;">
    <v-row>
      <v-col cols="6">
        <span class="title-tables-text">
          Filtrar leads por
        </span>
      </v-col>
      <v-col cols="6" style="text-align: end;">
        <v-btn icon @click="$router.go(-1)">
          <v-icon class="mr-1">mdi-close</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-form ref="form" v-model="formularioValido" lazy-validation>
          <v-row dense>
            <v-col v-if="error" cols="12">
              <v-alert type="error" dense>{{ error }}</v-alert>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="nombre"
                outlined
                dense
                label="Nombre"
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="apellidoPaterno"
                outlined
                dense
                label="Apellido Paterno"
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="apellidoMaterno"
                outlined
                dense
                label="Apellido Materno"
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="telefono"
                outlined
                dense
                label="Teléfono"
                prepend-inner-icon="mdi-phone"
                counter="10"
                :rules="[$rules.numeric, longDigitValidation(10)]"
                :maxLength="10"
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="email"
                outlined
                dense
                label="Correo electrónico"
                prepend-inner-icon="mdi-email"
                :rules="[$rules.email]"
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete
                v-model="medio"
                :items="currentLandings"
                label="Medio"
                item-value="Producto"
                item-text="landing"
                outlined
                dense
                primary
                clearable
                color="#00a7e4"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="inicio"
                    label="Fecha inicio"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    primary
                    clearable
                    color="#00a7e4"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="inicio"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="3">
              <v-menu
                v-model="menufin"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fin"
                    label="Fecha fin"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    primary
                    clearable
                    color="#00a7e4"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fin"
                  @input="menufin = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" style="text-align: end;">
        <v-btn
          class="common-botton"
          dark
          :disabled="!formularioValido"
          :loading="loading"
          @click="aplicarFiltros()"
        >
          <v-icon small class="mr-1">mdi-magnify</v-icon>
          <span>Aplicar filtros</span>
        </v-btn>
      </v-col>
    </v-row>
  </CommonCard>
</template>

<script>
import CommonCard from "@/components/commonComponents/CommonCard.vue";
import { getLandingsList } from "@/services/landings/landings.service";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    CommonCard,
  },

  data() {
    return {
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      telefono: "",
      email: "",
      medio: "",
      formularioValido: true,
      error: "",
      longDigitValidation(number) {
        return (v) =>
          !v ||
          v.length == number ||
          "Debe proporcionar " + number + " digitos";
      },
      landings: [],
      menu: false,
      menufin: false,
      inicio: "",
      fin: "",
      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
    };
  },

  methods: {
    aplicarFiltros() {
      this.error = "";
      const filtros = {
        ...(this.nombre && { nombre: this.nombre }),
        ...(this.email && { correo: this.email }),
        ...(this.apellidoPaterno && { apellido_paterno: this.apellidoPaterno }),
        ...(this.apellidoMaterno && { apellido_materno: this.apellidoMaterno }),
        ...(this.telefono && { telefono: this.telefono }),
        ...(this.medio && { medio: this.medio }),
      };
      if (Object.keys(filtros).length < 1) {
        this.error = "Debes ingresar al menos un filtro";
        this.formularioValido = false;
        return;
      } else
        this.$emit("filtrosModificados", {
          ...filtros,
          ...{ page: 0, size: 10 },
        });
    },
    async getLandings() {
      this.landings = await getLandingsList();
    },
  },

  mounted() {
    this.getLandings();
  },
  computed: {
    currentLandings() {
      if (
        this.rol == "ADMINCC" ||
        this.rol == "MANAGERCC" ||
        this.rol == "AGENTCC"
      ) {
        var landing = localStorage.getItem("landings");
        if (landing === null) {
          return [];
        }
        let array = landing.split(",").map(Number);
        return array
          .map((e) => {
            return this.landings.find((i) => i.id === e);
          })
          .filter((e) => e !== undefined);
      }
      else{
        return this.landings
      }
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 20px;
}

.v-expansion-panel {
  border-radius: 20px;
}
.v-expansion-panels {
  z-index: auto !important;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 20px !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}
</style>
