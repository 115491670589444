<template>
  <CommonCard style="padding-top: 10px;">
    <FiltrosLeads @filtrosModificados="realizarBusqueda" :loading="loading" />
    <div style="padding: 0px 40px 0px 40px;">
      <hr />
    </div>
    <ResultadosLeads
      :loading="loading"
      :leads="leads"
      :totalRecords="totalRecords"
      :filterPage="page"
      :filtersize="size"
      @busquedaSize="busquedaSize"
    />
  </CommonCard>
</template>

<script>
import { mainAxios } from "../../../mainAxios";
import logoSegurify from "@/assets/logo_segurify.png";
import FiltrosLeads from "@/components/leads/FiltrosLeads.vue";
import ResultadosLeads from "@/components/leads/ResultadosLeads.vue";
import CommonCard from "../../../components/commonComponents/CommonCard.vue";

export default {
  components: {
    FiltrosLeads,
    ResultadosLeads,
    CommonCard,
  },

  data() {
    return {
      logoSegurify,
      loading: false,
      leads: [],
      totalRecords: 0,
      currentFiltros: {},
      page: 0,
      size: 10,
    };
  },

  computed: {
    widthLogoSeg() {
      if (this.$vuetify.breakpoint.smAndDown) return "50";
      if (this.$vuetify.breakpoint.mdAndDown) return "50";
      return "80";
    },
  },

  methods: {
    realizarBusqueda(nuevosFiltros) {
      this.currentFiltros = nuevosFiltros;
      this.size = nuevosFiltros.size
      this.page = nuevosFiltros.page
      this.loading = true;
      this.leads = [];

      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      mainAxios
        .post("/v1/lead-segurify/list/filtros", nuevosFiltros, config)
        .then((response) => {
          this.loading = false;
          if (response.data.origenes[0].error) return;
          this.totalRecords = response.data.numRegisters;
          response.data.origenes.forEach((lead) => {
            this.leads.push({
              id: lead.origenId,
              nombre: `${lead.nombre} ${lead.apellidoPaterno} ${lead.apellidoMaterno}`,
              medio: lead.medio,
              ramo: lead.ramo,
              canal: lead.canal,
              producto: lead.producto,
              telefono: lead.telefono,
              correo: lead.correo,
              uuid: lead.origenUuid,
              uuid_lead_segurify: lead.uuid,
              foo: "bar",
              lead:
                lead.nombre +
                " " +
                lead.apellidopaterno +
                " " +
                lead.apellidomaterno,
              servicio_embebido: lead.servicio_embebido,
              embedded: false,
            });
          });
        });
    },
    busquedaSize(newSize) {
      this.realizarBusqueda({ ...this.currentFiltros, ...newSize });
    },
  },
};
</script>
