<template>
  <div style="margin: 40px; padding-bottom: 40px;">
    <v-card class="header-table-style">
      <v-toolbar dense flat dark color="#093E4C">
        <v-toolbar-title>
          <span>Resultados de la búsqueda</span>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          :items="leads"
          :headers="headers"
          no-data-text="Sin leads para mostrar"
          loading-text="Buscando leads..."
          hide-default-footer
          :loading="loading"
        >
          <template v-slot:item.view="{ item }">
            <v-btn icon small>
              <v-icon small @click="changeRoute(item)">
                mdi-eye
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions style="justify-content: center;">
        <div style="display: flex; justify-content: center; align-items: center;">
          <v-pagination
            v-model="page"
            :length="pages"
            total-visible="3"
            circle
            color="#0070C0"
          ></v-pagination>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    leads: {
      type: Array,
      default: () => [],
    },
    totalRecords: {
      type: Number,
      default: 0,
    },
    filterPage: {
      type: Number,
      default: 0,
    },
    filtersize: {
      type: Number,
      default: 0,
    },
    
  },

  data() {
    return {
      headers: [
        {
          text: "id",
          align: "center",
          value: "id",
        },
        {
          text: "Nombre",
          align: "center",
          value: "nombre",
        },
        {
          text: "Medio",
          align: "center",
          value: "medio",
        },
        {
          text: "Ramo",
          align: "center",
          value: "ramo",
        },
        {
          text: "Canal",
          align: "center",
          value: "canal",
        },
        {
          text: "Producto",
          align: "center",
          value: "producto",
        },
        {
          text: "Teléfono",
          align: "center",
          value: "telefono",
        },
        {
          text: "Correo",
          align: "center",
          value: "correo",
        },
        {
          text: "Ver",
          align: "end",
          value: "view",
        },
      ],
      size:10,
      page:1

    };
  },
  methods: {
    changeRoute(item) {
      this.$router.push({
        path:"/segurify/leads-segurify/lead/" + item.uuid_lead_segurify,
        query: {
          llamadaMasiva: false,
        }
      }
      );
    },
  },
  computed:{
    pages() {
      const totalPages = Math.ceil(this.totalRecords / this.size);
      return totalPages < 1 ? 1 : totalPages;
    },
  },
  watch:{
    page(){
      this.$emit('busquedaSize',{page:(this.page - 1), size:this.size})
    },
    filterPage(){
      if((this.filterPage + 1) != this.page)
        this.page = (this.filterPage + 1)
    },
    filtersize(){
      if(this.filtersize != this.size)
        this.size = this.filtersize
    }
  }
};
</script>